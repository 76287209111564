@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  font-size:10px
}

body{
  font-family: 'Noto Sans KR',sans-serif;
  font-size: 1.4rem;
  color: #333;
  font-weight: 400;
  line-height: 1;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
}

p{
  line-height: 1.5;
}

ul, ol{
  list-style: none;
  margin: 0;
}

dl, dd, dt{
  margin: 0;
  padding: 0;
}

a{
  color: #222;
  text-decoration: none;
}

table, tr, th, td{
  border-collapse: collapse;
}

address{
  font-style: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

caption{
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 1.2rem;
}

img, video, audio, object, embed, iframe{
  max-width: 100%;
  vertical-align: middle;
}

img, fieldset{
  border: 0 none;
} 

button{
  font-family: 'Roboto', sans-serif;
  border: 0;
  cursor: pointer;
  background: 0;
}

.ir{
  display: block;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
  overflow: hidden;
  background: no-repeat 0 0;
}

.clearfix::after{
  content: ' ';
  display: block;
  clear: both;
}

.screen-hidden{
  display: block !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  text-indent:-9999px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 0 !important;
}

.container{
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.container-fluid{
  width: 100%;
  height: 100%;
}

input, textarea{
  appearance: none;
  border-radius: 0;
  border : 0 none;
}

input::-ms-clear{
  display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}i

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #57849e;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 181, 0, 0.1);
}

@media screen and (max-width: 1240px) {
  .container {
    padding: 0 1.5rem;
  }
}
